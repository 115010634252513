import { get, getDatabase, ref as dbRef } from "firebase/database"


async function checkItemStock(items) {
    try {

        const getItemsStock = items.map(async item => {
            const res = (await get(dbRef(getDatabase(), `/inventory/ingredients/${item?.name?.toLowerCase()}`)))
            if (res.exists()) {
                // console.log(res.val())
                return res.val()
            }

        }).filter(item => item);

        const res = await Promise.all(getItemsStock)
        // console.log(res)
        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export { checkItemStock } 