<template>
  <layout :hasTopNav="false">
    <div class="add-ingredient-body">
      <top-nav
        :searchBar="false"
        :arrowback="true"
        :arrowbackpath="id ? `/inventory/ingredients/${formValues.name}` :`/inventory/ingredients/`"
      />
      <Text :size="24" :weight="700">{{ id ? "Edit" : "Add" }} Ingredient</Text>
      <form action="" class="mt-5 flex flex-col gap-4">
        <div class="">
          <!-- <label>Ingredient Name</label>
          <input
            class="ue-input-field"
            placeholder="Ingredient name"
            v-model="formValues.name"
          /> -->

          <label for="">Ingredient Name </label>
          <ue-select
            class="ue-input-field drop"
            :options="meals"
            label="name"
            v-model="formValues.name"
          >
            <template #option="option">
              <span :key="option.name">
                {{ option.name }}
              </span>
            </template>
          </ue-select>
        </div>

        <div class="">
          <label>Primary vendor</label>

          <ue-select
            class="ue-input-field ue-select"
            :options="[...vendors]"
            label="vendor_name"
            v-model="formValues.primary_vendor"
            placeholder="Select Primary Vendor"
          >
            <template #option="option">
              <span>{{ option.vendor_name }}</span>
            </template>
          </ue-select>
        </div>
        <div class="" v-if="!id">
          <label>Unit of measurement</label>

          <ue-select
            class="ue-input-field ue-select"
            :options="[...units]"
            label="name"
            v-model="formValues.unit"
            placeholder="Select Unit Of Measurement"
          >
            <template #option="option">
              <span>{{ option.name }}</span>
            </template>
          </ue-select>
        </div>
        <div class="">
          <label>Type</label>

          <ue-select
            class="ue-input-field ue-select"
            :options="[...types]"
            label="name"
            v-model="formValues.type"
            placeholder="Select Type"
          >
            <template #option="option">
              <span>{{ option.name }}</span>
            </template>
          </ue-select>
        </div>
        <div class="" v-if="!id">
          <label>Current inventory</label>
          <input
            type="number"
            class="ue-input-field"
            placeholder="Current inventory"
            v-model="formValues.current_inventory"
            min="0"
          />
        </div>
        <div class="">
          <label>Low stock amount alert</label>
          <input
            type="number"
            class="ue-input-field"
            placeholder="Low stock amount alert"
            v-model="formValues.low_stock_amount_alert"
            min="0"
          />
        </div>
        <div class="flex flex-col">
          <label>Upload food image</label>
          <input type="file" @input="handleFile" />
        </div>

        <img :src="preview" class="" />

        <!-- <button
          v-if="!id"
          class="rounded-pill w-[70%] ue-btn-gradient my-5 mx-auto py-3 save-btn"
          @click="create"
          :disabled="loading"
        >
          {{ loading ? "Loading" : "Save Ingredient" }}
        </button>
        <button
          v-if="id"
          class="rounded-pill w-[70%] ue-btn-gradient mt-5 mx-auto py-3 edit-btn"
          @click="edit"
          :disabled="loading"
        >
          {{ loading ? "Loading" : "Edit" }}
        </button> -->

        <ue-button
          :variant="'lg'"
          v-if="!id"
          @click="create"
          :disabled="loading"
          class="mx-auto mb-5"
        >
          {{ loading ? "Loading" : "Save Ingredient" }}</ue-button
        >
        <ue-button
          :variant="'lg'"
          v-if="id"
          @click="edit"
          :disabled="loading"
          class="mx-auto mb-5"
        >
          {{ loading ? "Loading" : "Edit Ingredient" }}</ue-button
        >
      </form>
    </div>
  </layout>
</template>

<script>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import TopNav from "@/components/TopNav.vue";
import { set, ref as dbRef, getDatabase, get } from "firebase/database";
import { ref, watch, watchEffect } from "vue";
import {
  ref as uploadRef,
  uploadBytes,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { projectStorage } from "@/firebase/config";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import { getImg } from "@/util";
import UeButton from "@/components/UeButton.vue";
import { meals as mealStore } from "@/views/store/meals";
import { computed } from "vue";
export default {
  components: { layout, Text, TopNav, UeButton },

  setup() {
    const route = useRoute();
    const types = ref([]);
    const units = ref([]);
    const vendors = ref([]);
    const image = ref({});
    const preview = ref("");
    const loading = ref(false);
    const prevImg = ref("");
    const useDefaultImage = ref(true);
    const router = useRouter();
    const toast = useToast({ position: "top-right" });
    const ingredients = ref([]);
    const image_default = ref("");
    watchEffect(async () => {
      const inges_ = (
        await get(dbRef(getDatabase(), "/inventory/ingredients"))
      ).val();

      ingredients.value = Object.keys(inges_ || {});
    });
    mealStore.dispatch("updateMeals");
    const meals = computed(() => {
      const meals_ = Array.from(
        new Set(mealStore.state.value.map((meal) => meal?.name?.toLowerCase()))
      ).filter(
        (meal) =>
          !ingredients.value
            .map((ingredients) => ingredients?.toLowerCase())
            .includes(meal)
      );
      // console.log({meals_})
      return meals_;
    });
    const meal = ref({});
    const formValues = ref({
      name: "",
      primary_vendor: "",
      unit: "",
      type: "",
      current_inventory: "",
      low_stock_amount_alert: "",
      image_url: "",
    });

    watchEffect(() => {
      const getTypes = async () => {
        const res = await get(dbRef(getDatabase(), "/inventory/"));
        const data = res.val();
        if (data) {
          types.value = Object.values(data.ingredient_types);
          units.value = Object.values(data.units);
          vendors.value = Object.values(data.vendors);
        }
      };
      getTypes();

      const getIngredient = async () => {
        const data = await get(
          dbRef(getDatabase(), `/inventory/ingredients/${route.params.id}`)
        );
        if (data.val()) {
          formValues.value = data.val();
          console.log(data.val().image_url, "---");
          preview.value = getImg(
            Array.isArray(data.val().image_url)
              ? data.val().image_url[0]
              : data.val().image_url || ""
          );
          prevImg.value = data.val().image_url;
        }
      };
      if (route.params.id) getIngredient();
    });

    const handleFile = (e) => {
      image.value = e.target.files[0];
      preview.value = URL.createObjectURL(image.value);
      useDefaultImage.value = false;
    };
    watchEffect(() => {
      // console.log(formValues.value?.name)
      const res = mealStore.state.value.find(
        (meal) =>
          formValues.value?.name?.toLowerCase() === meal?.name?.toLowerCase()
      );
      preview.value = res?.image;
      image_default.value = res?.image;
      if (res?.image) useDefaultImage.value = true;
    });

    const create = async (e) => {
      loading.value = true;
      e.preventDefault();

      try {
        if (!image.value) {
          toast.info("Uplad about");
        } else {
          if (!formValues.value.name) {
            toast.info("ingredient must have a name");
          } else {
            const image_name =
              Math.round(Math.random() * 1000000) + image.value.name;
            const storageRef = uploadRef(
              projectStorage,
              `ingredients/${image_name}`
            );
            // console.log(image.value);
            const res = await uploadBytes(storageRef, image.value);
            const downloadUrl = await getDownloadURL(
              uploadRef(getStorage(), `ingredients/${image_name}`)
            );
            // console.log(downloadUrl);

            formValues.value.image_url = useDefaultImage.value
              ? image_default.value
              : downloadUrl;

            await set(
              dbRef(
                getDatabase(),
                `/inventory/ingredients/${formValues.value.name}`
              ),
              { ...formValues.value, id: formValues.value.name }
            );
            toast.success("Ingredient added successfully");
            router.push("/inventory/ingredients");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    const edit = async (e) => {
      loading.value = true;
      e.preventDefault();
      //   console.log(formValues.value, image.value);
      if (image.value?.name) {
        const image_name =
          Math.round(Math.random() * 1000000) + image.value.name;
        const storageRef = uploadRef(
          projectStorage,
          `ingredients/${image_name}`
        );
        const res = await uploadBytes(storageRef, image.value);
        formValues.value.image_url = res.metadata.fullPath;
      } else {
        formValues.value.image_url = prevImg.value;
      }
      try {
        if (image.value == {}) {
          toast.info("Select an image");
        } else {
          //   console.log(formValues.value.image_url, image.value);
          if (!formValues.value.name) {
            toast.info("ingredient must have a name");
          } else {
            //   console.log(image.value)
            await set(
              dbRef(getDatabase(), `/inventory/ingredients/${route.params.id}`),
              { ...formValues.value, id: route.params.id }
            );
            toast.success("Ingredient edited successfully");
            // router.push("/inventory/ingredient");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    return {
      create,
      formValues,
      units,
      vendors,
      types,
      handleFile,
      preview,
      loading,
      id: route.params.id,
      edit,
      getImg,
      meals,
      meal,
    };
  },
};
</script>

<style lang="scss">
.add-ingredient-body {
  width: 100%;
  padding: 0px 40px;
  margin: auto;
  height: 100%;
  label {
    margin-bottom: 12px;
    color: #000;
    font-family: Work Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 96% */
    text-transform: capitalize;
  }
  input,
  .vs__search,
  .vs__selected {
    color: rgba(0, 0, 0, 1);
    font-family: Work Sans;
    font-size: 17px !important;
    &::placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-family: Work Sans;
      font-size: 17px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .save-btn,
  .edit-btn {
    font-size: 24px;
  }
  img {
    width: 300px;
  }
  select {
    option {
      width: 100%;
    }
  }
  select::-ms-expand {
    display: block;
  }
}
</style>