<template>
  <layout :hasTopNav="false">
    <div class="px-[40px] bg-light w-full h-full" v-if="!selectId">
      <TopNav arrowback :searchBar="false" />
      <v-dialog
        v-model="modalInView"
        class="bg-transparent"
        overlay-color="white"
        overlay-opacity="1"
        style="z-index: 20"
      >
        <div
          class="bg-white w-[100%] flex flex-col gap-3 w-[400px] rounded mx-auto p-3"
        >
          <Text :size="17" weight="600">{{ modalInView?.address }}</Text>
          <!-- <input
            class="outline-none py-2 border !w-[100%] rounded px-2"
            placeholder="Display name"
            v-model="modalInView.address"
          /> -->

          <input
            class="outline-none py-2 border !w-[100%] rounded px-2"
            placeholder="Delivery fee"
            v-model="modalInView.amount"
          />
          <ue-button @click="() => saveNewLocation()">Save </ue-button>
        </div>
      </v-dialog>
      <div class="d-flex items-center justify-content-between">
        <h2>
          {{ partner.name }}
        </h2>
      </div>

      <div class="border rounded p-2 mt-3 mb-2">
        <Text size="20" weight="600"> Add riders</Text>
        <ue-select
          class="ue-select !w-[400px]"
          :getOptionLabel="(user) => user.first_name"
          v-model="riders_in_partner"
          :options="[
            ...riders.filter((rider) => rider?.partner !== partern_id),
          ]"
          multiple
          placeholder="Add rider"
        >
          <template #option="option">
            <span>{{ option.first_name }} {{ option.last_name }} </span>
          </template>
        </ue-select>
        <UeButton @click="addRiders" class="mt-2"> Add </UeButton>

        <hr class="my-3" />

        <Text size="20" weight="600">Riders</Text>
        <div
          v-for="rider in riders.filter(
            (rider) => rider?.partner === partern_id
          )"
          :key="rider.id"
          class="flex items-center gap-[40px]"
        >
          <Text weight="600">
            {{ rider?.first_name }} {{ rider.last_name }}
          </Text>
          <span
            class="material-symbols-outlined cursor-pointer"
            @click="() => removeRider(rider)"
          >
            cancel
          </span>
        </div>
      </div>

      <div class="border p-3 rounded">
        <div class="flex justify-between">
          <Text size="20" weight="600">Orders by partner riders</Text>

          <div class="flex gap-3 items-center">
            <Text size="20" weight="600">
              Fee total: {{ formatPrice(totalPartnerFee) }}</Text
            >
            <time-drop-down
              :getDate="(e) => (timeFrame = e)"
              class="w-[200px]"
            />
            <UeButton @click="downloadoOrders"> Download </UeButton>
          </div>
        </div>
        <table class="order-table">
          <thead>
            <tr>
              <th class="table-header"></th>
              <th class="table-header">Order ID</th>
              <th class="table-header">Date</th>
              <th class="table-header">Customer Name</th>
              <!-- <th class="table-header">Service</th> -->
              <th class="table-header">Courier</th>
              <th class="table-header">Actual Amount</th>
              <th class="table-header">Partner fee</th>

              <!-- <th class="table-header">Action</th> -->
              <th class="table-header">Location</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(order, index) in paginatedOrders"
              :key="order.id"
              class="table-row"
              :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
            >
              <td class="table-data">
                {{ index + 1 + 15 * (currentPage - 1) }}
              </td>
              <td class="table-data">{{ getOrderID(order) }}</td>
              <td class="table-data">{{ formatDate(order.date) }}</td>
              <td class="table-data">
                {{
                  `${order?.customer?.firstName}  ${order?.customer?.lastName}`
                }}
              </td>
              <td
                v-if="order.locationType?.toLowerCase() === 'delivery'"
                class="table-data"
              >
                {{ order.courier.name ? order.courier.name : "Unassigned" }}
              </td>
              <td v-else class="table-data">N/A</td>
              <td class="table-data">
                {{ formatPrice(order?.delivery_address?.amount) }}
              </td>
              <td class="table-data">
                {{ formatPrice(getOrderPartnerFee(order)) }}
              </td>
              <td class="table-data">
                {{ order?.delivery_address?.address }}
              </td>
              <td class="table-data cursor-pointer"></td>
            </tr>
          </tbody>
        </table>
        <Pagination
          class="py-3"
          :currentPage="currentPage"
          :totalPages="totalPages"
          @page-change="goToPage"
        />
      </div>

      <div>
        <div class="gap-2 mt-3 flex flex-wrap w-[100%]">
          <div
            class="border flex justify-between w-[45%] items-center py-3 rounded px-3"
            v-for="(location, index) in delivery_locations"
            :key="location.name"
          >
            <div class="flex flex-col">
              <!-- <a
                target="_blank"
                :href="`https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`"
                class="text-[black]"
              > -->
              <Text :size="17" weight="600">{{ location?.address }}</Text>
              <!-- </a> -->
              <Text :size="17" weight="600">
                Actual: {{ formatPrice(location?.amount) }}
              </Text>
              <Text :size="17" weight="600">
                Partner fee:
                {{
                  formatPrice(
                    location?.partners?.[params.key]?.amount || location?.amount
                  )
                }}
              </Text>
            </div>

            <div class="flex flex-col gap-1">
              <ue-button
                class="cursor-pointer"
                @click="
                  () => {
                    modalInView = location;
                    indexInView = index;
                  }
                "
              >
                Edit
              </ue-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script setup>
import TopNav from "@/components/TopNav.vue";
import layout from "@/partials/Layout/layout.vue";
import { get, ref as dbRef, getDatabase, set, remove } from "firebase/database";
import { ref, onMounted, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { formatPrice, getOrdersByTimeFrame, sum } from "@/util";
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { useToast } from "vue-toast-notification";
import { store } from "@/views/store/store";
import Pagination from "@/components/Pagination.vue";
import TimeDropDown from "../../Operator/Dashboard/TimeDropDown.vue";
import { computed } from "vue";
import { getOrderID, formatDate } from "@/util";
import { json2csv } from "json-2-csv";
import { export2csv } from "../../Operator/Dashboard/exportcsv";

const partner = ref({});
const route = useRoute();
const delivery_locations = ref([]);
const modalInView = ref(null);
const indexInView = ref(-1);
const riders = ref([]);
const riders_in_partner = ref(null);
const { params } = route;
const timeFrame = ref({});
const partern_id = params.key;
const ordersByPartner = ref([]);

const fetchPartner = async () => {
  const res = (
    await get(dbRef(getDatabase(), `/logistics_partners/${params.key}`))
  ).val();
  partner.value = res;
};
onMounted(async () => {
  fetchPartner();
});
const fetchData = async () => {
  const res = (await get(dbRef(getDatabase(), `/delivery_address/`))).val();
  delivery_locations.value = res;
};
onMounted(async () => {
  riders.value = store.state.riders;
  fetchData();
});
const toast = useToast({ position: "top-right" });

const saveNewLocation = async () => {
  try {
    // const prev = delivery_locations.value[indexInView.value];

    const paterner_location_fee = modalInView.value?.amount;
    const newval = {
      partern_id,
      name: partner.value?.name,
      amount: paterner_location_fee,
    };

    await set(
      dbRef(
        getDatabase(),
        `delivery_address/${indexInView.value}/partners/${partern_id}`
      ),
      newval
    );
    toast.success("Location edited successfully");
    fetchData();
    modalInView.value = false;
  } catch (error) {
    console.log(error);
    toast.error("An error occured");
    fetchData();
  }
};

const addRiders = async () => {
  try {
    // console.log(riders_in_partner.value)
    if (riders_in_partner.value.length === 0) return 0;
    await fetchPartner();
    const riders_details = riders_in_partner.value?.map((rider) => [
      rider?.id,
      {
        name: `${rider?.first_name} ${rider?.last_name}`,
        id: rider?.id,
      },
    ]);
    const partern_id = params.key;
    // console.log(riders_details, partner.value?.riders);
    // console.log(Object.fromEntries(riders_details));
    const newPartnerRiders = {
      ...partner.value?.riders,
      ...Object.fromEntries(riders_details),
    };
    await set(
      dbRef(getDatabase(), `logistics_partners/${partern_id}/riders/`),
      newPartnerRiders
    );
    for (let i = 0; i < riders_in_partner.value.length; i++) {
      const rider = riders_in_partner.value[i];
      set(dbRef(getDatabase(), `courier/${rider?.id}/partner/`), partern_id);
    }
    partner.value.riders = newPartnerRiders;
    store.dispatch("updateRiders");
    riders.value = store.state.riders;
    riders_in_partner.value = [];
  } catch (error) {
    console.log(error);
  }
};

const removeRider = async (rider) => {
  console.log(rider.id);
  try {
    await remove(dbRef(getDatabase(), `courier/${rider?.id}/partner`));
    await remove(
      dbRef(
        getDatabase(),
        `logistics_partners/${partern_id}/riders/${rider.id}`
      )
    );
    toast.success("Rider remove successfully");
    store.dispatch("updateRiders");
    riders.value = store.state.riders;
  } catch (error) {
    console.log(error);
  }
};

watchEffect(async () => {
  const orders = await getOrdersByTimeFrame(
    timeFrame.value.start,
    timeFrame.value.end
  );
  console.log(orders);
  const partner_riders = Object.values(partner.value?.riders || {}).map(
    (rider) => rider?.id
  );
  console.log(partner.value, partner_riders);
  ordersByPartner.value = orders.filter((order) =>
    partner_riders.includes(order?.courier?.id)
  );
  console.log(ordersByPartner.value);
});

const getOrderPartnerFee = (order) => {
  console.log(delivery_locations.value);
  const locations = delivery_locations.value;
  const order_location = order?.delivery_address?.address;
  const location = locations.find(
    (location) => location.address === order_location
  );
  return parseInt(
    location?.partners?.[partern_id]?.amount || order?.delivery_address?.amount || order?.delivery
  );
};

const totalPartnerFee = computed(() => {
  timeFrame.value;
  const fees = ordersByPartner.value?.map(getOrderPartnerFee) || [];
  // console.log(fees);
  return sum(fees);
});

const downloadoOrders = () => {
  const ordersParams = ordersByPartner.value?.map((order) => ({
    "Order ID": order?.id,
    "Date": formatDate(order?.date),
    "Customer name": `${order?.customer?.firstName}  ${order?.customer?.lastName}`,
    "Courier name": `${order.courier.name}`,
    "Partner fee": formatPrice(getOrderPartnerFee(order)),
    "Location": order?.delivery_address?.address,
  }));
  const csv = json2csv(ordersParams);
  export2csv(csv, "partnersfee.csv", "text/csv;charset=utf-8;");
  console.log({ csv });
};

// Pagination
const ordersPerPage = 15;
const currentPage = ref(1);
const totalPages = computed(() =>
  Math.ceil(ordersByPartner.value.length / ordersPerPage)
);

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedOrders = computed(() => {
  const startIndex = (currentPage.value - 1) * ordersPerPage;
  const endIndex = startIndex + ordersPerPage;
  return ordersByPartner.value.slice(startIndex, endIndex);
});
</script>

<style scoped>
</style>