<template>
  <div @click="() => (action = props.action)">
    <slot />
    <v-dialog v-model="action">
      <div class="bg-white wallet-modal mx-auto p-2 rounded p-3" v-if="action">
        <Text :size="25" :weight="600" class="capitalize">
          {{ action?.replace("-", " ") }}
        </Text>

        <form @submit.prevent="submit" class="flex flex-col gap-3 mt-4">
          <div class="">
            <label @click="setPend" for="">Amount</label>
            <input
              required
              type="number"
              class="ue-input-field"
              v-model="formValues.amount"
            />
          </div>
          <div class="">
            <label>Reason</label>
            <textarea
              required
              class="border rounded w-100 border-[darkgrey] p-3 outline-none h-[100px]"
              v-model="formValues.reason"
              cols="8"
              rows="14"
            ></textarea>
          </div>
          <div class="w-100 flex justify-evenly">
            <button
              class="btn w-[150px] border bg-transaparent"
              @click="() => (action = false)"
            >
              Cancel
            </button>
            <button class="ue-btn w-[150px] btn" :disabled="loading">
              {{ loading ? "loading..." : "Confirm" }}
            </button>
          </div>
        </form>
      </div>
    </v-dialog>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import { ref, watchEffect } from "vue";
import { useToast } from "vue-toast-notification";
import { WalletTransaction } from "./updateWallet";
import { get, getDatabase, ref as dbRef, set } from "firebase/database";
import { removeUserPendingTransaction } from "../Orders/removeUserPendingTransaction";
import dayjs from "dayjs";
import { logActivity } from "@/util/activityLogger";

import { formatPrice } from "@/util";
const props = defineProps({
  action: String,
  user: Object,
  onSuccess: Function,
});

const action = ref(false);
const loading = ref(false);
const formValues = ref({
  amount: "",
  reason: "",
});

const user = ref(props.user);
watchEffect(() => {
  action.value;
  formValues.value.amount = undefined;
  formValues.value.reason = "";
});
watchEffect(() => {
  user.value = props.user;
});

const walletDetails = ref(props.user?.wallet || 0);
const toast = useToast({ position: "top-right" });

const test = {
  amount: "8800",
  basketWallet: 0,
  expiretime: '"2024-01-25T09:04:08.139Z"',
  id: "UE84915139-0849",
  order:
    '[{"restaurant":"CINDY’S","items":[{"id":"0026","name":"Level 1 Small Chops","amount":"1500","addons":[],"selectedPrice":"","extraPortion":"","quantity":1,"sides":[],"customize":[],"img":"https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/cindy%2Fjpeg-optimizer_LEVEL%201-3%20SMALL%20CHOPS-min.jpg?alt=media&token=0f7d9365-76e2-43f3-a9b0-6658c638610a","category":["Small Chops","Chicken"],"foodType":"SMALL CHOPS","type":"meal","available":true},{"id":"0028","name":"Level 3 Small Chops","amount":"3000","addons":[],"selectedPrice":"","extraPortion":"","quantity":2,"sides":[],"customize":[],"img":"https://firebasestorage.googleapis.com/v0/b/urban-eats-cloud.appspot.com/o/cindy%2Fjpeg-optimizer_LEVEL%201-3%20SMALL%20CHOPS-min.jpg?alt=media&token=0f7d9365-76e2-43f3-a9b0-6658c638610a","category":["Small Chops","Chicken","Seafood"],"foodType":"SMALL CHOPS","type":"meal","available":true}]}]',
  ordertype: "Delivery",
  time: '"2024-01-25T07:04:08.139Z"',
};

const setPend = () => {
  // set(dbRef(getDatabase(), `users/${user.value.uid}/pendingTransaction`), [
  //   test,
  // ]);
};

const autoConfirmOrder = async (amount) => {
  if (user.value?.pendingTransaction) {
    const array = user.value?.pendingTransaction || [];
    // console.log(array[0]);
    const wallet_balance_path = `users/${user.value.uid}/wallet/balance`;
    const wallet_history_path = `users/${user.value.uid}/wallet/history`;

    array.forEach(async (element, index) => {
      const orderId = element?.id || "";
      const order = (
        await get(dbRef(getDatabase(), `orders/${orderId}`))
      ).val();
      const balance =
        (await get(dbRef(getDatabase(), wallet_balance_path))).val() || 0;
      const history =
        (await get(dbRef(getDatabase(), wallet_history_path))).val() || [];

      if (!order.status.Cancelled.state) {
        if (balance >= order?.amount_due) {
          // console.log(order?.amount_due, "can pay",`orders/${orderId}/AwaitingPayment/state`,);
          await set(
            dbRef(
              getDatabase(),
              `orders/${orderId}/status/AwaitingPayment/state`
            ),
            false
          );
          await set(
            dbRef(getDatabase(), `orders/${orderId}/paidByTopup`),
            amount
          );
          await set(
            dbRef(getDatabase(), `orders/${orderId}/paymentMethod`),
            "wallet"
          );
          await set(
            dbRef(getDatabase(), `orders/${orderId}/status/Confirmed/`),
            {
              state: true,
              time: JSON.stringify(
                dayjs(Date.now()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
              ),
            }
          );
          await set(
            dbRef(getDatabase(), wallet_balance_path),
            balance - order?.amount_due
          );
          await set(dbRef(getDatabase(), wallet_history_path), [
            ...history,
            {
              amount: order?.amount_due,
              created: JSON.stringify(new Date().toISOString()),
              type: "order-purchase",
              order: orderId,
            },
          ]);

          await removeUserPendingTransaction({
            customer_uid: user.value.uid,
            order,
          });
          toast.success(`Topup cleared order ${orderId} `);
        }
      }
    });
  }
};

const submit = async () => {
  const walletTransaction = new WalletTransaction(user.value);

  //   console.log(walletDetails.value, action.value, formValues.value);
  if (!formValues.value.reason || formValues.value.amount <= 0) {
    toast.info("Transaction must have an amount greater than 0 and reason");
  } else {
    if (
      formValues.value.amount > walletDetails.value?.balance &&
      action.value === "deduct"
    ) {
      toast.info("Cannot deduct more than current wallet balance");
    } else {
      loading.value = true;
      try {
        const formattedAmount = formatPrice(formValues.value.amount);
        const name = user.value?.firstName + " " + user.value?.lastName;
        const reason = formValues.value.reason;
        if (action.value === "deduct") {
          await walletTransaction.withdrawFromWallet(
            formValues.value.amount,
            formValues.value.reason,
            () => (action.value = false)
          );
          await logActivity({
            message: `deducted ${formattedAmount} from ${name}'s wallet for ${reason}`,
          });
        }
        if (action.value === "top-up") {
          // autoConfirmOrder();
          await walletTransaction.depositToWallet(
            formValues.value.amount,
            formValues.value.reason,
            () => {
              action.value = false;
              autoConfirmOrder(formValues.value.amount);
            }
          );
          await logActivity({
            message: `topped-up ${formattedAmount} to ${name}'s wallet for ${reason}`,
          });
        }
        loading.value = false;
        formValues.value.amount = 0;
        formValues.value.reason = "";
        props.onSuccess();
      } catch (error) {
        console.log(error);
        loading.value = false;
      }
    }
  }
};
// watchEffect(() => {
//   action.value = props.action;
// });
</script>


<style lang="scss" scoped>
.wallet-modal {
  width: 500px;
  @media screen and (max-width: 600px) {
    width: 95%;
  }
}
</style>