

<template>
  <layout :hasTopNav="false" class="ingre">
    <v-dialog v-model="openModal">
      <update-ingredient-inventory :onClose="() => (openModal = false)" :defaultAction="action" />
    </v-dialog>
    <v-dialog v-model="openDelModal">
      <div class="w-[300px] bg-white mx-auto p-3 rounded flex flex-col gap-4">
        <Text weight="600" class="text-center" :size="20">
          Delete Inventory
        </Text>
        <Text weight="600" class="text-center" :size="14">
          Are you sure you want to delete {{ ingredient?.name }}?
        </Text>
        <div class="flex gap-2">
          <button
            class="border p-2 rounded w-[fit-content] mx-auto"
            @click="
              () => {
                openDelModal = false;
              }
            "
          >
            Cancel
          </button>
          <button
            class="p-2 text-white rounded w-[fit-content] bg-[red] mx-auto"
            @click="
              () => {
                deleteInventory();
              }
            "
          >
            Delete
          </button>
        </div>
      </div>
    </v-dialog>
    <div class="px-4 overflow-x-hidden h-[100%] relative">
      <top-nav :arrowback="true" :searchBar="false" />
      <header class="flex justify-between">
        <Text :size="27" :weight="600">{{ ingredient?.name }}</Text>

        <div class="flex gap-3">
          <router-link :to="`/inventory/ingredients/edit/${ingredient?.id}`">
            <Text
              :size="17"
              :weight="500"
              class="flex-1 underline"
              :color="'#F60100'"
              >Edit</Text
            ></router-link
          >
        </div>
      </header>

      <div
        class="flex rounded-[16px] h-[100px] items-center bg-white w-full my-5 px-[74px]"
      >
        <Text :size="17" :weight="500" class="flex-1">
          <img
            class="rounded"
            v-if="ingredient?.image_url"
            :src="ingredient?.image_url"
          />
          <span v-else>No image</span>
        </Text>
        <Text :size="17" :weight="500" class="flex-1">{{
          ingredient?.name
        }}</Text>
        <Text :size="17" :weight="500" class="flex-1">{{
          ingredient?.type?.name
        }}</Text>
        <Text :size="17" :weight="500" class="flex-1">{{
          ingredient?.primary_vendor?.name ||
          ingredient?.primary_vendor?.vendor_name
        }}</Text>
        <Text :size="17" :weight="500" class="flex-1"
          >{{ ingredient?.current_inventory }}
          {{ ingredient?.unit?.short }}</Text
        >
        <Text :size="17" :weight="500" class="flex-1"
          ><p :class="`${ingredient?.status?.color} px-3 w-[fit-content]`">
            {{ ingredient?.status?.message }}
          </p></Text
        >
      </div>
      <div class="flex gap-3 my-2">
        <v-btn
          :class="`disabled ${
            action == single_action ? 'ue-btn-gradient' : 'ue-btn-outline '
          } capitalize`"
          v-for="single_action in actions"
          :key="single_action"
          :variant="action == single_action ? `tonal` : 'outlined'"
          @click="
            () => {
              action = single_action;
              openModal = true;
            }
          "
        >
          {{ single_action }}
        </v-btn>
      </div>
      <ue-table :columns="column_1" :list="history" :perPage="10" />

      <!-- <div class="w-full mt-5">
        <Text :size="20" :weight="600">Reason</Text>
        <ue-table
          :columns="column_2"
          :list="
            history?.filter(
              (history) => history.action != 'receive' && !history.tally
            )
          "
          :perPage="10"
        />
      </div> -->

      <div class="flex justify-center gap-3 my-5 w-full">
        <!-- <v-btn
          class="ue-btn-gradient w-50 !py-0 !h-[43px] rounded-[30px]"
          @click="() => (openModal = true)"
          >Edit Inventory</v-btn
        > -->

        <!-- <ue-button :variant="'lg'" @click="() => (openModal = true)">
          Edit Inventory
        </ue-button> -->
        <ue-button
          class="!bg-[red]"
          :variant="'lg'"
          @click="() => (openDelModal = true)"
        >
          Delete Inventory
        </ue-button>
      </div>
    </div>
  </layout>
</template>

<script setup>
import layout from "@/partials/Layout/layout.vue";
import Text from "@/components/Text.vue";
import { ref, watchEffect } from "vue";
import { get, getDatabase, ref as dbRef, remove } from "firebase/database";
import { useRoute, useRouter } from "vue-router";
import { getImg } from "@/util";
import TopNav from "@/components/TopNav.vue";
import { getIngredientStatus } from "./getIngredientStatus";
import UpdateIngredientInventory from "./UpdateIngredientInventory.vue";
import UeTable from "@/components/UeTable.vue";
import UeButton from "@/components/UeButton.vue";
import { loadingScreen } from "@/views/store/loadingScreen";
import { logActivity } from "@/util/activityLogger";

// export default {
//   components: {
//     layout,
//     Text,
//     TopNav,
//     UpdateIngredientInventory,
//     UeTable,
//     UeButton,
//   },
//   setup() {
const route = useRoute();
const router = useRouter();
const ingredient = ref({});
const openModal = ref(false);
const history = ref([]);
const openDelModal = ref(false);
const actions = ["receive", "actual", "damage"];
const action = ref("");
const columns = [
  { name: "Date", key: "date" },
  { name: "Time", key: "time" },
  {
    name: "User",
    key: "user",
    render: (data) => `${data?.user?.first_name} ${data?.user?.last_name} `,
  },
  { name: "Action", key: "action", cellClass: "capitalize" },
  {
    name: "Stock In",
    key: "action",
    render: (data) =>
      data.action == "receive" ||
      data?.reason?.includes("reversed assembly") ||
      data?.reason?.includes("cancelled") ||
      data?.reason?.includes("Removed from stoplist")
        ? "" + data.quantity
        : "-",
  },
  {
    name: "Stock Out",
    key: "action",
    render: (data) =>
      data.action == "damage" || data?.reason?.includes("assembled")
        ? data.quantity
        : "-",
  },

  {
    name: "Reason",
    key: "reason",
    cellClass: "",
    render: (data) => data?.reason || "Receive",
  },
];
const column_1 = [...columns, { name: "Balance", key: "total" }];
const column_2 = [...columns, { name: "Reason", key: "reason" }];

watchEffect(async () => {
  openModal.value;
  const data = await get(
    dbRef(getDatabase(), `/inventory/ingredients/${route.params.id}`)
  );
  if (data.val()) {
    ingredient.value = getIngredientStatus(data.val());
    history.value = Object.values(ingredient.value?.history || {})?.reverse();
  }
});

const deleteInventory = async () => {
  try {
    loadingScreen.commit("openLoadingScreen", { loading: true });
    await remove(
      dbRef(getDatabase(), `/inventory/ingredients/${route.params.id}`)
    );

    await logActivity({
      message: `deleted inventory of ${route.params.id}. Last known qty is ${ingredient.value?.current_inventory}${ingredient.value?.unit?.short}`,
    });

    router.push("/inventory/ingredients/");
  } catch (error) {
    console.log(error);
  } finally {
    loadingScreen.commit("openLoadingScreen", { loading: false });
  }
};
// return {
//   ingredient,
//   getImg,
//   openModal,
//   column_1,
//   column_2,history
// };
// },
// };
</script>

<style lang="scss" scoped>
.ingre {
  img {
    width: 64px;
    max-height: 64px !important;
  }
}
</style>