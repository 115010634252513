<template>
  <layout>
    <top-nav :onSearch="search" :searchBar="false" :PrevNextIcon="false" />
    <Text class="pl-[40px] py-3" :size="27" :weight="700">
      Delivery Locations
    </Text>
    <v-dialog
      v-model="currentAddress"
      class="bg-transparent"
      overlay-color="white"
      overlay-opacity="1"
      style="z-index: 20"
    >
      <div
        class="bg-white w-[100%] flex flex-col gap-3 w-[400px] rounded mx-auto p-3"
      >
        <Text :size="17" weight="600">{{
          currentAddress?.address || "Add location"
        }}</Text>
        <input
          class="outline-none py-2 border !w-[100%] rounded px-2"
          placeholder="Display name"
          v-model="currentAddress.address"
        />
        <GMapAutocomplete
          placeholder="Delivery location"
          class="outline-none border p-2 !w-[100%] rounded"
          @place_changed="onPlace_changed"
        >
        </GMapAutocomplete>
        <span v-if="currentAddress?.deliveryLocation?.longitude">
          Longitude: {{ currentAddress?.deliveryLocation?.longitude }}
          <br />
          Latitude: {{ currentAddress?.deliveryLocation?.latitude }}
        </span>
        <input
          class="outline-none py-2 border !w-[100%] rounded px-2"
          placeholder="Delivery fee"
          type="number"
          v-model="currentAddress.amount"
        />

        <ue-select
          class=""
          :options="delivery_range_types?.map((range) => range.name)"
          label="name"
          v-model="currentAddress.delivery_range"
          placeholder="Select delivery range"
        >
          <template #option="option">
            <span>{{ option.name }}</span>
          </template>
        </ue-select>
        <div class="flex gap-2 items-center border px-2 rounded">
          <input
            class="outline-none py-2 !w-[100%]"
            placeholder="Radius"
            type="number"
            v-model="currentAddress.radius"
          />
          <div>KM</div>
        </div>
        <ue-button @click="() => saveNewLocation()">Save </ue-button>
      </div>
    </v-dialog>
    <div class="w-[95%] mb-4 mx-auto">
      <div class="flex gap-3">
        <UeButton @click="() => changeDeliveryFee('default')" class="mt-3">
          Reset delivery fee
        </UeButton>

        <UeButton @click="() => changeDeliveryFee('free')" class="mt-3">
          Start free delivery
        </UeButton>
        <UeButton @click="() => addlocation('free')" class="mt-3">
          Add delivery location
        </UeButton>
        <SwitchRange
          :locations="delivery_locations"
          :on-complete="fetchData"
          v-if="delivery_locations.length"
        />
      </div>
      <div class="gap-2 mt-3 flex flex-wrap w-[100%]">
        <div
          class="border flex justify-between w-[45%] items-center py-3 rounded px-3"
          v-for="(location, index) in delivery_locations"
          :key="location.name"
        >
          <div class="flex flex-col">
            <a
              target="_blank"
              :href="`https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`"
              class="text-[black]"
            >
              <Text :size="17" weight="600"
                >{{ location?.address }} ({{ location.delivery_range }})</Text
              >
            </a>
            <Text :size="17" weight="600">{{
              formatPrice(location?.amount)
            }}</Text>
          </div>

          <div class="flex flex-col gap-1">
            <ue-button
              @click="
                () => {
                  currentAddress = location;
                  indexInView = index;
                }
              "
            >
              Edit
            </ue-button>
          </div>
        </div>
      </div>
      <Text class="py-3" :size="20" :weight="700"> Defaults fees </Text>

      <fees :key="updateMainFee" />
      <location-range />
      <DeliveryTimeRanges />
      <delivery-radius :locations="delivery_locations" />
    </div>
  </layout>
</template>

<script setup>
import TopNav from "@/components/TopNav.vue";
import Fees from "./Fees.vue";
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { formatPrice } from "@/util";
import layout from "@/partials/Layout/layout.vue";
import { get, getDatabase, query, ref as dbRef, set } from "firebase/database";
import { onMounted, ref, watchEffect } from "vue";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import DeliveryRadius from "./DeliveryRadius.vue";
import DeliveryTimeRanges from "./DeliveryTimeRanges.vue";
import LocationRange from "./LocationRange.vue";
import SwitchRange from "./SwitchRange.vue";
import { useToast } from "vue-toast-notification";
import { logActivity } from "@/util/activityLogger";
// import DeliveryRadiusVue from "./DeliveryRadius.vue";

const delivery_locations = ref([]);
const currentAddress = ref(false);
const indexInView = ref(null);
const updateMainFee = ref(null);
const delivery_range_types = [
  {
    name: "regular",
    estimated_checkout_time: "45-65",
    estimated_time: "30-50",
    time: 50,
    unit: "mins",
  },
  {
    name: "extended",
    estimated_checkout_time: "60-90",
    estimated_time: "60-90",
    time: 60,
    unit: "mins",
  },
  {
    name: "peak-regular",
    estimated_checkout_time: "75-85",
    estimated_time: "40-80",
    time: 60,
    unit: "mins",
  },
  {
    name: "peak-extended",
    estimated_checkout_time: "90-120",
    estimated_time: "90-120",
    time: 90,
    unit: "mins",
  },
];
// console.log({ delivery_range_types });
const fetchData = async () => {
  const res = await get(dbRef(getDatabase(), "/delivery_address/"));
  if (res?.val()) delivery_locations.value = res?.val();
};

onMounted(() => {
  fetchData();
  //   console.log(delivery_locations.value);
});

const changeDeliveryFee = async (type) => {
  // let firsttt =
  // {
  //       "0": {
  //           "address": "Victoria Island",
  //           "amount": 1400,
  //           "delivery_range": "regular",
  //           "latitude": 6.4280556,
  //           "longitude": 3.4219444,
  //           "radius": 30
  //       },
  //       "1": {
  //           "address": "Ikota-vgc",
  //           "amount": 1800,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4609",
  //           "longitude": "3.5570"
  //       },
  //       "2": {
  //           "address": "Orchid road",
  //           "amount": 1900,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4317",
  //           "longitude": "3.5564",
  //           "radius": 20
  //       },
  //       "3": {
  //           "address": "Onikan",
  //           "amount": 2200,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4417",
  //           "longitude": "3.4019"
  //       },
  //       "4": {
  //           "address": "Lekki phase 1",
  //           "amount": 1400,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4478",
  //           "longitude": "3.4723"
  //       },
  //       "5": {
  //           "address": "Lagos island(marina)",
  //           "amount": 2200,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4514",
  //           "longitude": "3.3889"
  //       },
  //       "6": {
  //           "address": "Jakande-chevron",
  //           "amount": 1600,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4512",
  //           "longitude": "3.5306"
  //       },
  //       "7": {
  //           "address": "Ikoyi / banana island",
  //           "amount": 1500,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4667",
  //           "longitude": "3.4500"
  //       },
  //       "8": {
  //           "address": "Ikate",
  //           "amount": 1400,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.5072",
  //           "longitude": "3.3451"
  //       },
  //       "9": {
  //           "address": "Eko Atlantic",
  //           "amount": 1700,
  //           "delivery_range": "peak-regular",
  //           "latitude": 6.409217,
  //           "longitude": 3.4098365,
  //           "radius": 10
  //       },
  //       "10": {
  //           "address": "Dolphin estate",
  //           "amount": 1600,
  //           "delivery_range": "peak-regular",
  //           "latitude": "6.4560",
  //           "longitude": "3.4110"
  //       },
  // "11": {
  //     "address": "Addo Road",
  //     "amount": 3300,
  //     "delivery_range": "peak-extended",
  //     "latitude": 6.4841558,
  //     "longitude": 3.5743282,
  //     "radius": 16
  // },
  // "12": {
  //     "address": "Abraham Adesanya",
  //     "amount": 3500,
  //     "delivery_range": "peak-extended",
  //     "latitude": "6.4674",
  //     "longitude": "3.5982",
  //     "radius": 5
  // },
  // "13": {
  //     "address": "Badore road",
  //     "amount": 3700,
  //     "delivery_range": "peak-extended",
  //     "latitude": "6.4902",
  //     "longitude": "3.6087"
  // },
  // "14": {
  //     "address": "Ogombo",
  //     "amount": 3800,
  //     "delivery_range": "peak-extended",
  //     "latitude": 6.448874699999999,
  //     "longitude": 3.611827100000001,
  //     "radius": 30
  // },
  // "15": {
  //     "address": "Sangotedo",
  //     "amount": 4000,
  //     "delivery_range": "peak-extended",
  //     "latitude": "6.4665",
  //     "longitude": "3.6212"
  // },
  // "16": {
  //     "address": "Giwa gardens",
  //     "amount": 5000,
  //     "delivery_range": "extended",
  //     "latitude": 6.4591875,
  //     "longitude": 3.6275625,
  //     "radius": 10
  // }
  //   }
  // ;
  let main_delivery_fee = 1400;
  let value = delivery_locations.value.map((location) => ({
    ...location,
    amount: location?.fixed,
    // fixed: location?.fixed || location?.amount,
  }));
  let message = `reset delievry fee for all`;
  try {
    toast.info("updating..");
    if (type == "free") {
      value = delivery_locations.value.map((location) => ({
        ...location,
        amount: 10,
      }));
      main_delivery_fee = 10;
      message = `started free delivery for all`;
    }
    // console.log(value);
    await set(dbRef(getDatabase(), `delivery_address`), value);
    delivery_locations.value = value;
    await set(dbRef(getDatabase(), "/delivery_fee"), main_delivery_fee);
    await logActivity({ message });
    updateMainFee.value = Date.now();
    toast.success("Update complete");
  } catch (error) {
    console.log(error);
  }
};

const onPlace_changed = (e) => {
  const coords = e.geometry.location;
  currentAddress.value = {
    ...currentAddress.value,
    deliveryLocation: {
      latitude: coords?.lat(),
      longitude: coords?.lng(),
    },
  };
  // console.log(coords?.lat(), coords?.lng(), currentAddress.value);
  // currentAddress.value.deliveryLocation.latitude = coords?.lat();
  // currentAddress.value.deliveryLocation.longitude = coords?.lng();
};

const addlocation = async () => {
  console.log("first");
  currentAddress.value = {
    address: "",
    amount: null,
    range: null,
    fixed: null,
    deliveryLocation: {
      latitude: null,
      longitude: null,
    },
  };
};

const toast = useToast({ position: "top-right" });
const saveNewLocation = async () => {
  try {
    // console.log(currentAddress.value, indexInView.value);
    let prev = {};
    let logMessage = "";
    if (indexInView.value !== null) {
      prev = delivery_locations.value[indexInView.value];
      console.log(prev, currentAddress.value);
      logMessage = `edited (${currentAddress.value.address})`;
    }
    if (indexInView.value === null) {
      indexInView.value = delivery_locations.value.length;
      logMessage = `added a new location (${currentAddress.value.address})`;
    }
    // console.log({ prev });
    const newval = {
      address: currentAddress.value.address,
      delivery_range: currentAddress.value.delivery_range || "regular",
      radius: currentAddress.value.radius || 10,
      amount: parseInt(currentAddress.value.amount),
      fixed: parseInt(currentAddress.value.amount),
      latitude:
        currentAddress.value?.deliveryLocation?.latitude || prev.latitude,
      longitude:
        currentAddress.value?.deliveryLocation?.longitude || prev.longitude,
    };
    console.log(newval, indexInView.value, logMessage, );

    await set(
      dbRef(getDatabase(), `delivery_address/${indexInView.value}`),
      newval
    );
    await logActivity({
      message: logMessage,
    });
    toast.success("Location edited successfully");
    fetchData();
    currentAddress.value = false;
  } catch (error) {
    console.log(error);
    toast.error("An error occured");
    fetchData();
  }
};
</script>

<style lang="scss" scoped>
</style>