
import { projectFunctions } from "@/firebase/config";
import { httpsCallable } from "firebase/functions";

import { store } from "@/views/store/store";


export const logActivity = async ({ message }) => {

    try {
        const user = store.state?.user?.first_name + " " + store.state?.user?.last_name
        const activityLogger = httpsCallable(projectFunctions, "activityLogger");
        await activityLogger({
            user, message
        })
    } catch (error) {
        throw error
    }
}