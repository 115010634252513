<template>
  <div class="bg-[white] rounded-[12px] p-3 w-[400px] mx-auto">
    <Text :size="18" weight="600" class="text-center"> Update Inventory </Text>
    <Text>
      You are attempting to remove {{ props.meal?.name }} from stop list! please
      update the inventory, you may proceed without adding stock.
    </Text>
    <div class="flex items-center gap-2 my-3">
      <Text size="16"> Please update the inventory</Text>
      <input
        type="number"
        class="border rounded p-2"
        v-model="inventory_count"
      />
    </div>
    <div class="flex justify-center gap-3">
      <button @click="props.onCancel" class="border p-2 rounded">Cancel</button>
      <ue-button @click="onProceed" class=""> Proceed</ue-button>
    </div>
  </div>
</template>

<script setup>
import Text from "@/components/Text.vue";
import UeButton from "@/components/UeButton.vue";
import { loadingScreen } from "@/views/store/loadingScreen";
import { store } from "@/views/store/store";
import dayjs from "dayjs";
import {
  update,
  ref as dbRef,
  getDatabase,
  push,
  set,
} from "firebase/database";
import { ref } from "vue";

const props = defineProps({
  meal: Object,
  onCancel: Function,
  onProceed: Function,
});

const inventory_count = ref(0);
const onProceed = async () => {
  console.log(props.meal);
  const name = props.meal?.name?.toLowerCase();
  const count_path = `/inventory/ingredients/${name}/current_inventory`;
  const history_path = `/inventory/ingredients/${name}/history/`;
  const new_total = inventory_count.value + props.meal?.current_inventory;
  try {
    if (inventory_count.value) {
      loadingScreen.commit("openLoadingScreen", {
        loading: true,
        message: "Loading...",
      });
      const history_payload = {
        action: "Receive",
        date: dayjs().format("MMM DD, YYYY"),
        quantity: inventory_count.value,
        time: dayjs().format("hh:mm A"),
        total: new_total,
        reason: "Removed from stoplist",
        user: {
          email: store.state.user.email,
          first_name: store.state.user.first_name,
          last_name: store.state.user.last_name,
          uid: store.state.user.uid,
        },
      };

      await set(dbRef(getDatabase(), count_path), new_total);
      await push(dbRef(getDatabase(), history_path), history_payload);
    }
    props.onProceed();
  } catch (error) {
    console.log(error, "--");
  } finally {
    loadingScreen.commit("openLoadingScreen", {
      loading: false,
      message: "",
    });
  }
};
</script>

<style lang="scss" scoped>
</style>