<template>
  <div>
    <!-- <v-dialog v-model="openConfirmModal"> -->
    <div class="w-[400px] rounded mx-auto bg-white p-3">
      <Text :size="18" :weight="600" class="text-center mb-3"
        >Some items in this order is more than the current stock inventory
      </Text>
      <div v-for="item in moreThanStockItems" :key="item.name">
        <span class="font-bold"> {{ item.name }}</span> has
        {{ item.stock?.current_inventory }}
        {{ item?.stock?.unit?.short }} left, attemping to assemble
        {{ item?.stock?.unit?.short }}
        {{ item?.prepared }}
      </div>
      <div class="flex justify-center gap-3 mt-3">
        <button class="border px-3 rounded" @click="() => props.onCancel()">
          Cancel
        </button>
        <ue-button class="border rouned bg-" @click="proceed">
          Proceed
        </ue-button>
      </div>
    </div>
    <!-- </v-dialog> -->
  </div>
</template>

<script setup>
import { get, getDatabase, ref as dbRef } from "firebase/database";
import Text from "./Text.vue";
import UeButton from "./UeButton.vue";
import { ref } from "vue";
import { onMounted, watchEffect } from "vue";
const props = defineProps({
  order: Object,
  items: Array,
  item: Object,
  onProceed: Function,
  inventory: Array,
  onCancel: Function,
});

const items = ref([]);
const moreThanStockItems = ref([]);
const openConfirmModal = ref(false);
watchEffect(async () => {
  if (props.item) {
    items.value = [props.item];
  }
  if (props.items) {
    items.value = props.items;
  }
  if (props.order) items.value = props.order?.items;
});

function checkStockBeforeAssemblingOrder(items) {
  moreThanStockItems.value = [];
  try {
    const inventory = props.inventory;
    const items_more_than_current_stock = [];
    items.forEach((item) => {
      //   console.log(item);
      const stock = inventory.find(
        (inven) => inven?.name === item?.name?.toLowerCase()
      );

      if (stock) {
        moreThanStockItems.value.push({
          ...item,
          stock,
        });
      }
    });
    // console.log(moreThanStockItems.value)
  } catch (error) {
    console.log(error);
    return error;
  }
}

watchEffect(async () => {
  props.inventory;
  checkStockBeforeAssemblingOrder(items.value);
});

const proceed = () => {
  // console.log(moreThanStockItems.value)
  props.onProceed();
  moreThanStockItems.value = [];
};
</script>

<style lang="scss" scoped>
</style>