<template>
  <head>
    <link
      rel="stylesheet"
      href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css"
    />
  </head>

  <layout :hasTopNav="false">
    <div></div>
    <div class="px-[40px] bg-light w-full h-full">
      <!-- <TopNav /> -->
      <div class="d-flex justify-content-between orderClass p-3 w-full">
        <div class="w-full">
          <div class="flex w-full items-center justify-between">
            <h1>
              <Text :size="27" :weight="700">Activity Log</Text>
            </h1>
          </div>
        </div>
      </div>
      <section class="orderList !p-0 !bg-white">
        <div>
          <table class="order-table">
            <thead>
              <tr>
                <th class="table-header"></th>
                <th class="table-header">User</th>
                <th class="table-header">Action</th>
                <th class="table-header">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(log, index) in paginatedLogs"
                :key="log?.id"
                class="table-row"
                :class="index % 2 === 0 ? 'even-row' : 'odd-row'"
              >
                <td class="table-data">
                  {{ index + 1 + 15 * (currentPage - 1) }}
                </td>
                <td class="table-data">
                  {{ log?.user }}
                </td>
                <td class="table-data">
                  {{ log?.message }}
                </td>
                <td class="table-data">
                  {{ dayjs(log?.created).format("MMM DD YYYY. hh:mmA") }}
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            class="py-3"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @page-change="goToPage"
          />
        </div>
      </section>
    </div>
  </layout>
</template>
              
            <script setup>
import { ref, computed, watchEffect } from "vue";
// import OrderModal from "./OrderModal.vue";
import TopNav from "@/components/TopNav.vue";
import Text from "@/components/Text.vue";
import {
  sum,
  formatDate,
  getUeOrderId,
  // diffByStatus,
  formatPrice,
  checkTotal,
} from "@/util";
import Layout from "@/partials/Layout/layout.vue";
import { store } from "@/views/store/store";
import {
  set,
  ref as dbRef,
  getDatabase,
  get,
  push,
  query,
} from "firebase/database";
import dayjs from "dayjs";
import CustomerName from "@/components/CustomerName.vue";
import OrderID from "@/components/OrderID.vue";
import Pagination from "@/components/Pagination.vue";

const logs = ref([]);

watchEffect(async () => {
  try {
    const quer = await get(dbRef(getDatabase(), `activity_logs`));

    logs.value = Object.values(quer?.val() || {})?.toReversed();
  } catch (error) {
    console.log(error, "----");
  }
});

// Pagination
const logPerPage = 10;
const currentPage = ref(1);
const totalPages = computed(() => Math.ceil(logs.value.length / logPerPage));

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};

const paginatedLogs = computed(() => {
  const startIndex = (currentPage.value - 1) * logPerPage;
  const endIndex = startIndex + logPerPage;
  return logs.value.slice(startIndex, endIndex);
});
</script>
  